$theme-colors: () !default;
$theme-colors: map-merge(("primary": #4fe48b,
        "secondary": #c8ced3,
        "success": #4dbd74,
        "info": #20a8d8,
        "warning": #ffc107,
        "danger": #f86c6b,
        "light": #f0f3f5,
        "dark": #2b2e83),
    $theme-colors);


$gray-base: #181b1e !default;
$gray-100: #f0f3f5 !default; // lighten($gray-base, 85%);
$gray-200: #e4e7ea !default; // lighten($gray-base, 80%);
$gray-300: #c8ced3 !default; // lighten($gray-base, 70%);
$gray-400: #acb4bc !default; // lighten($gray-base, 60%);
$gray-500: #bdc5c8 !default; // lighten($gray-base, 50%);
$gray-600: #73818f !default; // lighten($gray-base, 40%);
$gray-700: #43535a !default; // lighten($gray-base, 30%);
$gray-800: #2f353a !default; // lighten($gray-base, 10%);
$gray-900: #23282c !default; // lighten($gray-base, 5%);
$black: #000 !default;

$grays: () !default;
$grays: map-merge(("100": $gray-100,
        "200": $gray-200,
        "300": $gray-300,
        "400": $gray-400,
        "500": $gray-500,
        "600": $gray-600,
        "700": $gray-700,
        "800": $gray-800,
        "900": $gray-900),
    $grays);


$azul: #0C326F !default;
$verde-principal: #4fe48b !default;
$orange: #f59a31 !default;
$laranja-favorito: #f59a31 !default;
$cinza-claro: #bdc5c8;
$cinza-super-claro: #f0f0f0;
$cinza-escuro: #43535a;
$azul-principal: #0167A8;
$azul-aleatorio: #2b3084;
$azul-escuro: #2b2e83;
$azul-secundario: #2daae1;
$vermelho: #ba0000;
$vermelho-escuro: #ba0000;
$vermelho-alerta: #f53a31;
$verde-permitido: #00a650;
.empreendimento {

    .icon-oficio {
        margin-right: 5px;
    }

    .red {
        color: red;
        font-weight: bold;
    }

    .green {
        color: #00a650;
        font-weight: bold;
    }

    .blue {
        color: #0167A8;
        font-weight: bold;
    }

    .yellow {
        color: #fcba03;
        font-weight: bold;
    }

    table {
        display: contents;
    }

    .licenciamento-button {
        width: 350px;
        margin-top: 10px;
        margin-left: 15px;

        i {
            margin-left: 10px;
        }
    }

    .map {
        width: 100%;
        height: 300px;
        margin: auto;
        margin-bottom: 20px;
    }

    h1 {
        color: $azul;
        font-weight: 600;
        margin-top: 10px;
    }

    .row {
        padding-left: 0;
        padding-right: 0;
    }

    .label {
        font-size: 14px;
        font-weight: 600;
    }

    .caption {
        font-size: 14px;
        font-weight: 400;
    }

    .button-contrato {
        margin-top: 20px;
        margin-bottom: 20px;
        width: 25%;
        margin-left: auto;
        margin-right: auto;
    }

    .button-adicionar {
        font-size: 18px !important;
    }

    .icon-margin {
        margin-left: 10px;
    }

    label {
        padding: 0 !important;
        color: $gray-700 !important;
    }

    .btn-primary {
        font-size: 24px;
        background-color: $azul;
        border: none;
    }

    .collapse-header {
        background-color: $azul;
        color: white;
        padding: 10px;
        border-radius: 10px;
        width: 80%;
        margin-top: 20px;
        margin-bottom: 30px;

        span {
            font-size: 18px;
        }

        .icon {
            font-size: 18px;
            margin-left: 10px;
        }
    }

    .collapse-container {

        margin-bottom: 20px;

        .collapse-item {
            background-color: $gray-500;
            color: #43535a;
            padding: 10px;
            margin: 10px;
            border-radius: 10px;
            width: 70%;

            span {
                font-size: 18px;
            }

            .icon {
                font-size: 18px;
                margin-left: 10px;
            }
        }
    }

}
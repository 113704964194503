.app-header {
    background-color: #0167A8;
    height: 100px;
    
    .logo-sidebar {
        position: absolute;
        left: 15px;
    }

    .menu-item {
        color: white;
        text-decoration: none;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }

        &.active {
            text-decoration: underline;
        }
    }

    .logout-button {
        background-color: transparent;
        color: white;
        font-weight: bold;
        border: 1px solid white;
        border-radius: 10px;
        padding: 5px;
    }
}
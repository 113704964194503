.header-wrapper {
    float: right;
}

.px-3 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.container-lg {
    padding: 0;
}

.header {

    & > .container-fluid:first-child {
        margin-left: auto;
        margin-right: 0;
        width: unset;
    }
}

.card-header {
    background-color: $azul-principal;
    color: white;
}

.container-lg {
    max-width: unset;
}

.side-menu-icon {
    margin-right: 10px;
}

button {
    color: white !important;
}

.file-input {
    display: none;
}

.file-input-fake-button {
    background-color: $verde-permitido;
    color: white;
    border-radius: 0.25rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 0.375rem 0.75rem;
    margin-top: 20px;
    cursor: pointer;
}

.divider {
    border: 1px solid $gray-100;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 10px;
}

.hidden {
    display: none;
}

.pointer {cursor: pointer;}
.grab {cursor: grab;}
.grabbing {cursor: grabbing;}

.spinner-results {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
    background-color: white;
    padding: 30px;
    border-radius: 30px;
}

.modal-pdf {

    margin-left: 20%;

    .modal-content {
        width: 70vw;
        text-align: center;
        text-align: -webkit-center;
    }

    .modal-body {
        max-height: 73vh;
        overflow-y: scroll;
    }
}

// input {
//     padding: 0 !important;
//     border: none !important;
//     border-bottom: 1px solid white !important;
//     border-radius: 0 !important;
//     background-color: $azul !important;
//     color: white !important;
//     font-weight: 600;

//     &::-webkit-input-placeholder {
//         color: white !important;
//     }

//     &:focus, &:active {
//         color: white !important;
//         background-color: $azul !important;
//         border-color: white !important;
//     }
// }

.align-center {
    text-align: center;
}

// label {
//     padding: 0 !important;
    // color: white !important;
//     font-weight: 600;
// }

.btn-primary {
    background-color: $verde-principal;
}

button {
    border-radius: 10px !important;
}

.logo-sidebar {
    width: 200px;    
}

.leaflet-container {
    height: 100%;
    border-radius: 12px;
}

.bold {
    font-weight: 600;
}

.voltar-button {
    width: 100px;
    margin-top: 20px;
    margin-bottom: 20px;

    i {
        margin-right: 5px;
    }
}
.licenciamento {

    .table {
        margin-top: 50px;
        background-color: white;
        border-radius: 12px;

        td, th {
            padding: 10px 35px !important;
        }
    }

    .avancar-button {
        margin-left: 20px;
    }

    .diploma-container {

        display: flex;
        width: 900px;
        margin-bottom: 20px;
        margin-top: 20px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;

        .diploma {
            width: inherit;
            cursor: pointer;
            color: #2daae1;
            border: 1px solid #2daae1;
            border-radius: 12px;
            padding: 10px;
            margin-right: 35px;

            span {
                font-weight: bold;
            }
        }
    }

    .futura {}

    .atual {

        td {
            color: $azul;
            font-weight: bold;
        }
    }

    .concluida {

        td {
            color: #019421;
            font-weight: bold;
        }

    }

    .situacao-item {
        width: 130px;
        min-width: 130px;
    }

    .fase-container {
        display: flex;
        width: 900px;
        margin: auto;

        h4 {
            margin-right: 20px;
        }

        .fase-subcontainer {
            width: inherit;
            border: 1px solid #2daae1;
            display: inline-flex;
            margin-right: 35px;
            border-radius: 12px;
            padding: 20px;

            &.lai {
                border: 1px solid #019421;

                .capsule-item {
                    background-color: #019421;

                    &.active {
                        background-color: #00a650;
                    }
                }
            }
        }
    }

    .capsule-item {
        padding: 10px;
        background-color: #0167A8;
        border-radius: 12px;
        color: white;
        width: fit-content;
        cursor: pointer;

        &.active {
            background-color: #2daae1;
            padding: 20px;
        }
    }

    .tilted-fase {
        margin-top: 40px;
        transform: rotate(45deg);
    }
}

.modal-config {

    .modal-content {
        width: 750px;
    }

    .modal-body {
        height: 500px;
        overflow-y: auto;
    }
}
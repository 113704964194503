.usuarios {

    width: 80%;
    margin: auto;

    td {
        max-width: 200px;
    }

    .icon-cell {
        width: 150px;

        i {
            margin: 5px;
            cursor: pointer;
        }
    }
}

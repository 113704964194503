.visualizador {

    .progress-bar-container {
        width: 100%;

        h3 {
            color: $azul;
        }

        .progress-bar-custom {

            &>div>div {
                background-color: $azul !important;
            }

        }

        .passeio-button {
            height: 25px;
            padding-left: 5px;
            padding-right: 5px;
            padding-bottom: 13px;
            padding-top: 0px;
        }
    }

    .camada-button-card {
        margin-top: 15px;
        width: 858px;
        
        .card-body {
            padding-top: 0;
            padding-bottom: 0;

            label {
                margin-top: 0;
            }
        }
    }

    .file-input-fake-button {
        color: white !important;
        margin-bottom: 15px;
        padding: 14px !important;
    }

    .primarios {
        height: 300px;
        overflow-y: scroll;
    }

    .licenciamento-button {
        margin-bottom: 10px;

        i {
            margin-left: 5px;
        }
    }

    .leaflet-popup-content-wrapper {
        min-width: 500px;
        overflow-x: scroll;

        .featureInfo {
            font-size: 12px;
        }
    }

    .nav-tabs {
        margin-bottom: 20px;

        .nav-item {
            width: 50%;
            text-align: center;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
            border-top-right-radius: 16px;
            border-top-left-radius: 16px;
            background-color: #E9E9E9;

            i {
                color: #43535a;
            }

            .active {
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;
                border-top-right-radius: 16px;
                border-top-left-radius: 16px;

                i {
                    color: $azul-principal;
                }
            }
        }
    }

    .titulo {
        font-weight: 600;
        margin-left: 10px;
    }

    .texto-roxo {
        color: #4F3C88;
        font-weight: 600;
        font-size: 14px;
    }

    .escopo-container {
        background-color: #F9F9F9;
        font-size: 11px;
        border-radius: 24px;
        padding: 5px;
        display: inline-flex;

        i {
            line-height: 4;
            font-size: 13px;
            margin-left: 4px;
            margin-right: 5px;
        }
    }

    .rua {
        width: 50px;
        height: 50px;
    }

    .map-container {
        height: 80vh;
    }

    .card {
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        margin-right: 20px;
        margin-bottom: 20px;
        padding: 10px;
    }

    h1 {
        color: $azul;
        font-weight: 600;
        margin-top: 10px;
    }

    .row {
        padding-left: 0;
        padding-right: 0;
    }

    .label {
        font-size: 14px;
        font-weight: 600;
    }

    .caption {
        font-size: 14px;
        font-weight: 400;
    }

    .button-contrato {
        margin-top: 20px;
        margin-bottom: 20px;
        width: 25%;
        margin-left: auto;
        margin-right: auto;
    }

    .button-adicionar {
        font-size: 18px !important;
        width: 60%;
        margin: auto;
        margin-top: 20px;
        max-width: 300px;
    }

    .icon-margin {
        margin-left: 10px;
    }

    label {
        padding: 0 !important;
        color: $gray-700 !important;
    }

    .btn-primary {
        font-size: 24px;
        background-color: $azul;
        border: none;
    }

    .collapse-header {
        background-color: $azul;
        color: white;
        padding: 10px;
        border-radius: 10px;
        width: 80%;
        margin-top: 20px;

        span {
            font-size: 18px;
        }

        .icon {
            font-size: 18px;
            margin-left: 10px;
        }
    }

    .collapse-container {

        margin-bottom: 20px;

        .collapse-item {
            background-color: $gray-500;
            color: #43535a;
            padding: 10px;
            margin: 10px;
            border-radius: 10px;
            width: 70%;

            span {
                font-size: 18px;
            }

            .icon {
                font-size: 18px;
                margin-left: 10px;
            }
        }
    }

}